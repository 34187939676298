import createReducer from "../lib/utils/CreateReducer";
import * as actions from "../actions/ChatActions";
import _uniqBy from "lodash/uniqBy";
import _sortBy from "lodash/sortBy";
import _omitBy from "lodash/omitBy";

export const getInitialState = () => ({
  loading: false,
  errors: {},
  chatChannels: [],
  chatMessages: [],
  allChannels: {
    threads: [],
  },
  chatHeader: "Chat",
  members: [],
  unseenContent: {},
  contentUpdated: {},
  chatThread: {},
  privateThread: {},
  page: 1,
  unread_messages: 0,
  lastMessageNumber: 0,
  firstMessageNumber: null,
});

export default createReducer(getInitialState, {
  [actions.Types.GET_CHAT_CHANNELS_REQUEST]: () => ({
    loading: true,
    errors: {},
    chatMessages: [],
  }),

  [actions.Types.GET_CHAT_CHANNELS_SUCCESS]: (state, { payload }) => {
    // console.log(`payload: `, action);
    return {
      loading: false,
      chatChannels: payload,
    };
  },
  [actions.Types.GET_THREAD_MESSAGES_REQUEST]: (state, { payload }) => {
    return {
      loading: true,
    };
  },
  [actions.Types.GET_THREAD_MESSAGES_SUCCESS]: (state, { payload }) => {
    return {
      loading: false,
      chatMessages: _sortBy(
        _uniqBy(
          [...state.chatMessages, ...payload.messages].filter(
            (item) => item.uuid
          ),
          "uuid"
        ),
        (message) => message.message_number
      ),
      lastMessageNumber: Math.max(
        state.lastMessageNumber,
        payload.last_message_number
      ),
      firstMessageNumber:
        state.firstMessageNumber === null ||
        payload.first_message_number < state.firstMessageNumber
          ? payload.first_message_number
          : state.firstMessageNumber,
    };
  },

  [actions.Types.GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_REQUEST]: (
    state,
    { payload }
  ) => {
    return {
      loading: true,
      members: [],
    };
  },

  [actions.Types.GET_NEWEST_MESSAGES_FROM_CHAT_THREAD_SUCCESS]: (
    state,
    { payload }
  ) => {
    console.log("Redux state of chat :");
    console.log("Payload", [...payload.messages.reverse()]);
    console.log("State", [...state.chatMessages]);
    return {
      loading: false,
      page: payload.messages.length > 0 ? payload.page : state.page,
      chatMessages: _sortBy(
        _uniqBy([...payload.messages.reverse(), ...state.chatMessages], "uuid"),
        (message) => message.message_number
      ),
    };
  },

  [actions.Types.DELETE_CHAT_SUCCESS]: (state, { payload }) => {
    return {
      loading: false,
      chatChannels: state.chatChannels.filter((e) => e.uuid !== payload),
    };
  },

  [actions.Types.GET_NEWEST_UNREAD_MESSAGES_FROM_CHAT_THREAD_SUCCESS]: (
    state,
    { payload }
  ) => {
    return {
      loading: false,
      chatMessages: _uniqBy(
        [...state.chatMessages, ...payload.messages],
        "uuid"
      ),
      unread_messages: payload.unread_messages,
    };
  },

  [actions.Types.SET_CHAT_CHANNEL_TITLE]: (state, { payload }) => ({
    chatHeader: payload.title,
  }),
  [actions.Types.ADD_CHAT_MEMBERS]: (state, { payload }) => ({
    members: [],
  }),

  [actions.Types.GET_CHAT_MEMBERS_SUCCESS]: (state, { payload }) => {
    return {
      loading: false,
      members: payload,
    };
  },

  [actions.Types.GET_CHAT_THREAD_REQUEST]: (state, { payload }) => {
    return {
      loading: false,
      chatMessages: [],
      chatThread: {},
    };
  },

  [actions.Types.GET_CHAT_THREAD_SUCCESS]: (state, { payload }) => {
    return {
      loading: false,
      chatThread: payload,
    };
  },

  [actions.Types.GET_1V1_CHAT]: (state, { payload }) => {
    return {
      loading: true,
      privateThread: {},
    };
  },

  [actions.Types.SET_1V1_CHAT]: (state, { payload }) => {
    return {
      loading: false,
      privateThread: payload,
    };
  },

  [actions.Types.GET_CONTENT_UPDATED_SUCCESS]: (state, { payload }) => {
    return {
      loading: false,
      contentUpdated: payload,
    };
  },

  [actions.Types.GET_UNSEEN_CONTENT_SUCCESS]: (state, { payload }) => {
    return {
      loading: false,
      unseenContent: payload,
    };
  },

  [actions.Types.ADD_NEW_MESSAGE_TO_CHAT_THREAD_AS_PENDING_STATUS]: (
    state,
    { payload }
  ) => {
    const message = payload;
    //    message.status = "pending";
    return {
      ...state,
      chatMessages: [...state.chatMessages, message],
    };
  },
  [actions.Types.REMOVE_MESSAGE_FROM_CHAT_THREAD]: (state, { payload }) => {
    state.chatMessages = state.chatMessages.filter(
      (message) =>
        message.message !== payload.message && message.status !== "failed"
    );
    return state;
  },
  [actions.Types.POST_NEW_MESSAGE_TO_CHAT_THREAD_FAILURE]: (
    state,
    { payload }
  ) => {
    let chatMessages = state.chatMessages;
    const index = chatMessages.findIndex(
      (message) =>
        message.thread_uuid === payload.thread_uuid &&
        message.message === payload.message &&
        message.sender_uuid === payload.sender_uuid
    );

    if (index !== -1) {
      const message = payload;
      message.status = "failed";
      chatMessages[index] = message;
    }
    return {
      ...state,
      chatMessages: [...chatMessages],
    };
  },
  [`${actions.Types.SEARCH_CHAT_THREAD}_SUCCESS`]: (state, { payload }) => {
    return {
      ...state,
      allChannels: payload,
    };
  },
  [actions.Types.RESET_CHAT_SCREEN]: (state, { payload }) => {
    console.log(getInitialState());
    return getInitialState();
  },
});
