import { call, put, takeLatest } from "redux-saga/effects";
import AuthConstants from "../constants/AuthConstants";
import RoutePathConstants from "../constants/RoutePathConstants";
import AuthDataStorage from "../helpers/StorageHelpers/AuthDataStorage";
import UserInfoStorage from "../helpers/StorageHelpers/UserInfoStorage";
import history from "../history";
import SigninValidation from "../lib/validators/SigninValidation";
import AuthRepository from "../repositories/AuthRepository";
import { store } from "../store";
import { getMyProfileDetail } from "../actions/UserActions";
import { pushNotificationTokenRequest } from "../actions/AppConfigAction";
import { getToken } from "../firebase";

import _isEmpty from "lodash/isEmpty";

const {
  SIGNIN,
  VALIDATE_LOGIN_DATA,
  VALIDATE_MAGIC_LOGIN_TOKEN,
  Invalid_login_token_error_code,
  SIGNOUT,
  SIGNIN_PHONE,
} = AuthConstants;
const { startScreen, dashboard, profileCreationGuest } = RoutePathConstants;

export function* watchSignin() {
  yield takeLatest(`${SIGNIN}_REQUEST`, function*({ payload: { email, language } }) {
    try {
      const validationErrors = SigninValidation.validate({ email });
      if (!_isEmpty(validationErrors)) throw validationErrors;

      const response = yield call(AuthRepository.signin, email, language);
      if (response)
        if (response.success) {
          AuthDataStorage.storeLoginCodeSecret(response.logincode_secret);

          yield call(login);
        }
    } catch (errors) {
      yield put({
        type: `${SIGNIN}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

function* loginPhone() {
  yield put({
    type: `${SIGNIN}_SUCCESS`,
  });
}

export function* watchSigninPhone() {
  yield takeLatest(`${SIGNIN_PHONE}_REQUEST`, function*({
    payload: { phone, language },
  }) {
    try {
      const response = yield call(AuthRepository.signinPhone, phone, language);
      if (response)
        if (response.success) {
          AuthDataStorage.storeLoginCodeSecret(response.logincode_secret);
          yield call(loginPhone);
        }
    } catch (errors) {
      yield put({
        type: `${SIGNIN}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

function* login() {
  yield put({
    type: `${SIGNIN}_SUCCESS`,
  });
}

export function* watchValidateLoginData() {
  yield takeLatest(`${VALIDATE_LOGIN_DATA}_REQUEST`, function*({
    payload: { loginData, appId },
  }) {
    try {
      if (!AuthDataStorage.getDeviceId()) {
        AuthDataStorage.storeDeviceId(loginData["pseudo_user_identifier"]);
      }
      const response = yield call(AuthRepository.validateLoginData, loginData);
      if (response.user && response.success) {
        yield put({
          type: "HIDE_ALERT",
        });
        if (
          !AuthDataStorage.isAuthDataAvailable(appId) &&
          _isEmpty(AuthDataStorage.getUserAuthentication())
        ) {
          AuthDataStorage.storeApiKey(response.user.apikey);
          AuthDataStorage.storeUuid(response.user.uuid);
          AuthDataStorage.storeAuthentication(response.user.accepted);
        }
        if (response.user.accepted) {
          UserInfoStorage.storeUserRole(response.user.roles[0]);
          if (response.user.roles[0] !== "ROLE_PSEUDO")
            history.push(`/${dashboard}`);
        } else {
          if (loginData.logincode) history.push(`/${profileCreationGuest}`);
          else history.push(`/${startScreen}`);
        }
        yield put({
          type: `${VALIDATE_LOGIN_DATA}_SUCCESS`,
        });
        if (
          !_isEmpty(AuthDataStorage.getUuid()) &&
          AuthDataStorage.getApiKey()
        ) {
          store.dispatch(getMyProfileDetail());
          requestNotificationToken();
        }
      } else {
        yield put({
          type: "DISPLAY_ALERT",
          payload: {
            alertOptions: {
              alertText:
                "It seems that the link you used is invalid or has already been used. Please try signing in again.",
              leftOption: "OK",
              leftOptionVisible: true,
            },
          },
        });

        if (AuthDataStorage.isAuthDataAvailable(appId)) {
          history.push(`/${dashboard}`);
        } else {
          history.push(`/${startScreen}`);
        }
        yield put({
          type: `${VALIDATE_LOGIN_DATA}_FAILURE`,
          payload: {},
        });
      }
    } catch (errors) {
      yield put({
        type: `${VALIDATE_LOGIN_DATA}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchValidateMagicLoginToken() {
  yield takeLatest(`${VALIDATE_MAGIC_LOGIN_TOKEN}_REQUEST`, function*({
    payload: { token },
  }) {
    try {
      const response = yield call(
        AuthRepository.validateMagicLoginToken,
        token
      );

      if (response.success === undefined) {
        yield put({
          type: `${VALIDATE_MAGIC_LOGIN_TOKEN}_SUCCESS`,
        });
        AuthDataStorage.storeApiKey(response.apikey);
        AuthDataStorage.storeUuid(response.uuid);

        if (!_isEmpty(response.profile)) {
          if (
            !_isEmpty(AuthDataStorage.getUuid()) &&
            AuthDataStorage.getApiKey()
          )
            store.dispatch(getMyProfileDetail());
          history.push(`/${dashboard}`);
        }
      } else {
        yield put({
          type: "DISPLAY_ALERT",
          payload: {
            alertOptions: {
              alertText: "Something went wrong, please try again later",
              leftOption: "OK",
              leftOptionVisible: true,
            },
          },
        });
        yield put({
          type: `${VALIDATE_MAGIC_LOGIN_TOKEN}_FAILURE`,
          payload: { errors: Invalid_login_token_error_code },
        });
      }
    } catch (errors) {
      yield put({
        type: `${VALIDATE_MAGIC_LOGIN_TOKEN}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

export function* watchSignout() {
  yield takeLatest(`${SIGNOUT}_REQUEST`, function*() {
    try {
      yield call(AuthRepository.signout);
      yield put({
        type: `${SIGNOUT}_SUCCESS`,
      });
      setTimeout(() => {
        AuthDataStorage.removeApiKeyAndUuid();
        UserInfoStorage.removeUserRole();
        window.location.assign(`/${startScreen}`);
      }, 1000);
    } catch (errors) {
      yield put({
        type: `${SIGNOUT}_FAILURE`,
        payload: { errors },
      });
    }
  });
}

function requestNotificationToken() {
  getToken(setTokenFound);
}

function setTokenFound(token) {
  AuthDataStorage.storeNotificationToken(token);
  store.dispatch(pushNotificationTokenRequest(token));
}
