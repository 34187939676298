import { fork, all } from "redux-saga/effects";

import {
  watchGetAppConfig,
  watchGetAppFrontpage,
  watchGetAppWorkspaces,
  watchChangeAppLanuage,
  watchPushNotificationToken,
} from "./AppConfig";
import chatSagas from "./Chat";
import {
  watchGetDocs,
  watchGetCallToken,
  watchSearchSuggestion,
  watchGetPost,
} from "./Common";
import {
  watchSignin,
  watchValidateLoginData,
  watchValidateMagicLoginToken,
  watchSignout,
  watchSigninPhone,
} from "./Auth";
import {
  watchGetUser,
  filterSearch,
  watchGetUserListAfterSortResult,
  watchGetUserDetail,
  watchEndorseUser,
  watchRemoveEndorseUser,
  watchFavouriteUser,
  watchRemoveFavouriteUser,
  watchGetFavouriteUsers,
  watchGetMatchRecommendations,
  watchGetMyProfileDetail,
  watchGetSameTopicUsers,
  watchGetSearchTopic,
  watchUpdateEditedUserProfile,
  watchUploadUserProfileImage,
  watchUploadDocument,
  watchUploadDocumentCancel,
  watchgetUserTotalCount,
  watchEmptyUser,
  watchHideUser,
  watchUnhideUser,
  watchSendFeedback,
  watchToggleOnline,
} from "./User";
import {
  watchGetOrganizationDetail,
  watchJoinOrganization,
  watchSearchOrganizationDetail,
  watchGetOrganizationList,
} from "./Organization";
import {
  watchGetScheduleList,
  watchGetScheduleDetail,
  watchGetSessionDetail,
  watchReserveSeat,
  watchCancelReservation,
  watchFavouriteSession,
  watchRemoveFavouriteSession,
  watchGetMyPastSessionsRequest,
  watchGetMyUpcomingSessionsRequest,
} from "./Schedule";

import {
  watchGetAllThreads,
  watchGetThread,
  watchPostThread,
} from "./ChatCall";

export default function* root() {
  yield all([
    fork(watchGetAppConfig),
    fork(watchGetAppFrontpage),
    fork(watchGetAppWorkspaces),
    fork(watchChangeAppLanuage),
    fork(watchPushNotificationToken),

    fork(watchSignin),
    fork(watchSigninPhone),
    fork(watchValidateLoginData),
    fork(watchValidateMagicLoginToken),
    fork(watchSignout),
    fork(watchgetUserTotalCount),
    fork(watchGetUser),
    fork(filterSearch),
    fork(watchGetUserListAfterSortResult),
    fork(watchGetUserDetail),
    fork(watchEndorseUser),
    fork(watchRemoveEndorseUser),
    fork(watchFavouriteUser),
    fork(watchRemoveFavouriteUser),
    fork(watchGetFavouriteUsers),
    fork(watchGetMatchRecommendations),
    fork(watchGetMyProfileDetail),
    fork(watchGetSameTopicUsers),
    fork(watchGetSearchTopic),
    fork(watchUpdateEditedUserProfile),
    fork(watchUploadUserProfileImage),
    fork(watchUploadDocument),
    fork(watchUploadDocumentCancel),
    fork(watchEmptyUser),
    fork(watchSendFeedback),
    fork(watchToggleOnline),

    fork(watchGetOrganizationDetail),
    fork(watchJoinOrganization),
    fork(watchSearchOrganizationDetail),
    fork(watchGetOrganizationList),

    fork(watchGetScheduleList),
    fork(watchGetScheduleDetail),
    fork(watchGetSessionDetail),
    fork(watchReserveSeat),
    fork(watchCancelReservation),
    fork(watchFavouriteSession),
    fork(watchRemoveFavouriteSession),
    fork(watchGetMyPastSessionsRequest),
    fork(watchGetMyUpcomingSessionsRequest),

    fork(watchGetDocs),
    fork(watchGetCallToken),
    fork(watchGetAllThreads),
    fork(watchGetThread),
    fork(watchPostThread),

    fork(watchSearchSuggestion),
    fork(watchGetPost),

    fork(watchHideUser),
    fork(watchUnhideUser),
    ...chatSagas,
  ]);
}
