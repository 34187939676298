import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import AppConfigConstants from "../constants/AppConfigConstants";
import AuthDataStorage from "../helpers/StorageHelpers/AuthDataStorage";
import LocalStorage from "../lib/LocalStorage";
import AppConfigRepository from "../repositories/AppConfigRepository";

const {
  GET_APP_CONFIG,
  GET_APP_FRONTPAGE,
  GET_WORKSPACES,
  CHANGE_APP_LANGUAGE,
  PUSH_NOTIFICATION_TOKEN,
} = AppConfigConstants;

export function* watchGetAppConfig() {
  yield takeEvery(`${GET_APP_CONFIG}_REQUEST`, function*({
    payload: { appIdentifier },
  }) {
    try {
      const appConfig = yield call(
        AppConfigRepository.getAppConfig,
        appIdentifier
      );
      if (appConfig.success === false) {
        window.location.assign("https://content.mesensei.com/404/");
      } else {
        AuthDataStorage.storeApiUuid(appConfig.app.uuid);
        AuthDataStorage.storeAppKey(appConfig.app.appkey);
        AuthDataStorage.storeAppName(appConfig.app.settings.full_name);
        AuthDataStorage.storeAppParentUuid(appConfig.app.parent_app_uuid);
        if (appConfig && appConfig.app && appConfig.app.colors) {
          AuthDataStorage.storeAppDefaultColor(
            appConfig.app.colors.default_background
          );
        }
        let appsWorkspaces = JSON.parse(LocalStorage.get("appsWorkspaces"));

        if (!appsWorkspaces) {
          AuthDataStorage.initAppWorkspaces(
            appConfig.app.parent_app_uuid
              ? appConfig.app.parent_app_uuid
              : appConfig.app.uuid,
            appConfig.app.parent_app_uuid
              ? {}
              : {
                  name: appConfig.app.name,
                  identifier: appConfig.app.identifier,
                  app_uuid: appConfig.app.uuid,
                  app_icon: appConfig.app.images.app_icon.image_url,
                  image_url: appConfig.app.images.app_icon.image_url,
                }
          );
        } else {
          if (appConfig.app.type_string === "APP") {
            AuthDataStorage.storeNewAppWorkspaces(appConfig.app.uuid, {
              name: appConfig.app.name,
              identifier: appConfig.app.identifier,
              app_uuid: appConfig.app.uuid,
              app_icon: appConfig.app.images.app_icon.image_url,
              image_url: appConfig.app.images.app_icon.image_url,
            });
          } else if (appConfig.app.type_string === "WORKSPACE") {
            AuthDataStorage.storeAppWorkspacesList(
              appConfig.app.parent_app_uuid,
              {
                name: appConfig.app.name,
                identifier: appConfig.app.identifier,
                app_uuid: appConfig.app.uuid,
                app_icon: appConfig.app.images.app_icon.image_url,
                image_url: appConfig.app.images.app_icon.image_url,
              }
            );
          }
        }

        if (
          !AuthDataStorage.getLang() ||
          !appConfig.app.settings.languages.includes(AuthDataStorage.getLang())
        )
          AuthDataStorage.storeLanguage(appConfig.app.settings.languages[0]);
        yield put({
          type: `${GET_APP_CONFIG}_SUCCESS`,
          payload: appConfig.app,
        });
      }
    } catch (errors) {
      console.log(errors);
      yield put({
        type: `${GET_APP_CONFIG}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetAppFrontpage() {
  yield takeEvery(`${GET_APP_FRONTPAGE}_REQUEST`, function*() {
    try {
      const response = yield call(AppConfigRepository.getAppFrontpage);
      if (response.success === true) {
        yield put({
          type: `${GET_APP_FRONTPAGE}_SUCCESS`,
          payload: response.frontpage,
        });
      }
    } catch (errors) {
      yield put({
        type: `${GET_APP_FRONTPAGE}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchGetAppWorkspaces() {
  yield takeEvery(`${GET_WORKSPACES}_REQUEST`, function*() {
    try {
      const response = yield call(AppConfigRepository.getAppWorkspaces);
      if (response.success === true) {
        let parentUuid = AuthDataStorage.getAppParentUuid();
        if (!parentUuid) {
          parentUuid = AuthDataStorage.getAppUuid();
          AuthDataStorage.editAppWorkspace(parentUuid, response.workspaces);
        }
        yield put({
          type: `${GET_WORKSPACES}_SUCCESS`,
          payload: response.workspaces,
        });
      }
    } catch (errors) {
      yield put({
        type: `${GET_WORKSPACES}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchChangeAppLanuage() {
  yield takeLatest(`${CHANGE_APP_LANGUAGE}_REQUEST`, function*({
    payload: { language },
  }) {
    try {
      yield put({
        type: `${CHANGE_APP_LANGUAGE}_SUCCESS`,
        payload: language,
      });
    } catch (errors) {
      yield put({
        type: `${GET_WORKSPACES}_FAILURE`,
        payload: errors,
      });
    }
  });
}

export function* watchPushNotificationToken() {
  yield takeLatest(`${PUSH_NOTIFICATION_TOKEN}_REQUEST`, function*({
    payload: { token },
  }) {
    try {
      const response = yield call(
        AppConfigRepository.pushNotificationTokenRequest,
        token
      );
      if (response.success === true)
        yield put({
          type: `${PUSH_NOTIFICATION_TOKEN}_SUCCESS`,
          payload: token,
        });
    } catch (errors) {
      yield put({
        type: `${PUSH_NOTIFICATION_TOKEN}_FAILURE`,
        payload: errors,
      });
    }
  });
}
