import tokeys from "tokeys";

export default tokeys([
  "GET_USER",
  "FILTER_SEARCH",
  "GET_USER_DETAIL",
  "GET_MY_PROFILE_DETAIL",
  "ENDORSE_USER",
  "REMOVE_ENDORSE_USER",
  "FAVOURITE_USER",
  "REMOVE_FAVOURITE_USER",
  "GET_FAVOURITE_USERS",
  "GET_MATCH_RECOMMENDATION",
  "GET_SAME_TOPIC_USERS",
  "GET_TOPIC_DISCUSSION_THREAD",
  "SORT_RESULT",
  "SEARCH_TOPIC",
  "GET_TOPIC",
  "SELECTED_SORT_OPTION",
  "UPDATE_EDITED_USER_PROFILE",
  "UPLOAD_PROFILE_IMAGE",
  "UPLOAD_DOCUMENT",
  "Waiting_for_approval_error_code",
  "POP_UP_MESSAGE",
  "EMPTY_USER",
  "HIDE_USER",
  "UNHIDE_USER",
  "SEND_FEEDBACK",
  "TOGGLE_ONLINE",
]);
