export default {
  startScreen: "guest/start",
  loginScreen: "guest/login",
  signinScreen: "guest/signin",
  welcomingScreen: "guest/welcome",
  magicLogin: "guest/magicLogin",
  dashboard: "authed/main",
  home: "authed/home",
  search: "authed/search",
  searchGroup: "authed/search/organizations",
  platform: "authed/platform",
  user: "authed/user",
  profileDetail: "authed/profile",
  organization: "authed/organization",
  organisation: "authed/organisation",
  favourite: "authed/favourite",
  settings: "authed/settings",
  editProfile: "authed/settings/edit/profile",
  profileEdit: "authed/profile/edit",
  editProfileGuest: "guest/edit/profile",
  navigationsList: "authed/navigationList",
  workspace: "authed/workspace",
  eventDetail: "authed/eventDetail",
  eventList: "authed/eventList",
  eventSchedule: "schedule",
  serviceTerms: "authed/legal/terms",
  privacyPolicy: "authed/legal/privacy",
  aboutScreen: "authed/about",
  serviceTermsGuest: "guest/legal/terms",
  privacyPolicyGuest: "guest/legal/privacy",
  myQREventTicket: "authed/myQREventTicket",
  sameTopicUserListScreen: "authed/topic",
  appSendFeedback: "authed/feedback",
  appSendFeedbackguest: "guest/feedback",
  myChat: "authed/chat/list",
  myCalls: "authed/calls/list",
  myAgenda: "authed/myAgenda",
  chat: "authed/chat",
  switchLang: "authed/switch/language",
  profileCreationGuest: "guest/profile/create",
  post: "authed/post",
  page: "authed/page",
};
