import LocalStorage from "../../lib/LocalStorage";
import StorageKeysConstants from "../../constants/StorageKeysConstants";

const { authDataStorageKeys, userInfoStorageKeys } = StorageKeysConstants;

export default {
  getUserRole: () =>
    LocalStorage.get(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        userInfoStorageKeys.userRole
      }`
    ),
  storeUserRole: (userRole) => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);

    if (appId && userRole) {
      LocalStorage.set(`${appId}${userInfoStorageKeys.userRole}`, userRole);
    }
  },
  removeUserRole: () => {
    LocalStorage.remove(
      `${LocalStorage.get(authDataStorageKeys.appId)}${
        userInfoStorageKeys.userRole
      }`
    );
  },
  removePseudoUser: () => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    LocalStorage.remove(`${appId}:pseudo_user_identifier`);
  },

  removePseudoUserName: () => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    LocalStorage.remove(`${appId}:pseudo_name`);
  },

  getPseudoUser: () => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    LocalStorage.get(`${appId}:pseudo_user_identifier`);
    return LocalStorage.get(`${appId}:pseudo_user_identifier`);
  },

  getWorkspaceName: () => {
    return LocalStorage.get(authDataStorageKeys.appId);
  },

  getUserLanguage: () => {
    const appId = LocalStorage.get(authDataStorageKeys.appId);
    return LocalStorage.get(`${appId}:language`);
  },
};
