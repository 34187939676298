import config from '../config';

export default {
  get base() {
    return config.apiHost;
  },

  get mediaBase() {
    return config.mediaHost;
  },

  get mediaKey() {
    return config.mediaApikey;
  },

  getAppConfig(appIdentifier) {
    console.log(`${this.base}/v4/content/app/${appIdentifier}`);
    return `${this.base}/v4/content/app/${appIdentifier}`;
  },
  getAppFrontpage() {
    return `${this.base}/v4/client/app/frontpage`;
  },
  getAppWorkspaces() {
    return `${this.base}/v4/client/app/workspaces`;
  },
  signin() {
    return `${this.base}/v4/client/account/login/request`;
  },
  validateLoginData() {
    return `${this.base}/client/account/login`;
  },
  validateMagicLoginToken(token) {
    return `${this.base}/api/magiclogin/validates/${token}`;
  },
  signout() {
    return `${this.base}/client/account/logout`;
  },
  pushNotificationTokenRequest() {
    return `${this.base}/v4/client/account/pushtoken`;
  },
  getUser() {
    return `${this.base}/client/user/list/users`;
  },
  filterSearch(searchInput) {
    return `${
      this.base
    }/client/user/list/users?search=${searchInput}`;
  },
  sortResult(params) {
    var { id, page } = params;
    return `${
      this.base
    }/client/user/list/users?limit=300&page=${page}&sort=${id}`;
  },
  getUserDetail(userId) {
    return `https://devapi.mesensei.com/users/${userId}`;
  },
  getMyProfileDetail() {
    return `${this.base}/client/account/profile`;
  },
  updateEditedUserProfile() {
    return `${this.base}/client/account/profile`;
  },
  getOrganizationDetail(organizationId) {
    return `${this.base}/v4/client/organizations/${organizationId}`;
  },
  joinOrganization(organizationId) {
    return `${this.base}/client/organization/${organizationId}/join`;
  },
  searchOrganization(searchInput) {
    return `${
      this.base
    }/client/organization/list?search=${searchInput}`;
  },
  getOrganizationList(page) {
    return `${
      this.base
    }/client/organization/list?page=${page}&limit=30`;
  },
  endorseUser(topicId, userId) {
    return `${
      this.base
    }/client/user/${userId}/topic/${topicId}/endorsement`;
  },
  removeEndorseUser(topicId, userId) {
    return `${
      this.base
    }/client/user/${userId}/topic/${topicId}/endorsement`;
  },
  favouriteUser(userId) {
    return `${this.base}/client/user/${userId}/favourite`;
  },
  removeFavouriteUser(userId) {
    return `${this.base}/client/user/${userId}/favourite`;
  },
  getFavouriteUsers() {
    return `${this.base}/v4/client/users/list/favourites`;
  },
  getMatchRecommendations() {
    return `${this.base}/v4/client/users/list/recommendations`;
  },
  // getSameTopicUsers(topicId) {
  //   return `${this.base}/client/user/list/users?sort=2&topic_uuid=${topicId}`;
  // },

  // Also gives the discussion thread for the topic
  getSameTopicUsers(topicId) {
    return `${this.base}/v4/client/topics/${topicId}`;
  },
  getScheduleList() {
    return `${this.base}/v4/client/schedule/events`;
  },
  getScheduleDetail(scheduleId) {
    return `${this.base}/client/schedule/uuids/${scheduleId}`;
  },
  getSessionDetail(sessionId) {
    return `${this.base}/v4/client/schedule/sessions/${sessionId}`;
  },
  reserveSeat(sessionId) {
    return `${
      this.base
    }/client/schedule/session/${sessionId}/reservation`;
  },
  cancelReservation(sessionId) {
    return `${
      this.base
    }/client/schedule/session/${sessionId}/reservation`;
  },
  favouriteSession(sessionId) {
    return `${
      this.base
    }/client/schedule/session/${sessionId}/favourite`;
  },
  removeFavouriteSession(sessionId) {
    return `${
      this.base
    }/client/schedule/session/${sessionId}/favourite`;
  },
  getMyPastSessions() {
    return `${this.base}/client/schedule/session/list/past`;
  },
  getMyUpcomingSessions() {
    return `${this.base}/client/schedule/session/list/upcoming`;
  },
  getSearchTopicInput(topicSearchInput, page, limit = 20) {
    // return `${this.base}/api/topic/search?search=${topicSearchInput}`;
    return `${
      this.base
    }/v4/client/topics/list/popular?limit=${limit}&search=${topicSearchInput}`;
  },
  getSearchTopic(id) {
    // return `${this.base}/api/topic/search?search=${topicSearchInput}`;
    return `${this.base}/client/topic/${id}`;
  },
  getUnseenContent() {
    return `${this.base}/v4/client/messaging/unseen-content`;
  },
  getContentUpdated() {
    return `${this.base}/v4/client/messaging/content-updated`;
  },
  getThreadMessages(
    thread_uuid,
    limit,
    before_message_number,
    after_message_number
  ) {
    return `${
      this.base
    }/v4/client/messaging/threads/${thread_uuid}/messages?before_message_number=${before_message_number}&limit=${limit}&after_message_number=${after_message_number}&setread=true`;
  },
  //  getNewestChatMassagesFromThread(
  getNewThreadMessages(thread_uuid, after_message_number) {
    return `${
      this.base
    }/v4/client/messaging/threads/${thread_uuid}/messages?after_message_number=${after_message_number}&setread=true`;
  },
  postNewChatMessageToThread(thread_uuid) {
    return `${
      this.base
    }/v4/client/messaging/threads/${thread_uuid}/messages`;
  },
  getAllChatThreads() {
    return `${this.base}/v4/client/messaging/threads`;
  },
  createChatThread() {
    return `${this.base}/v4/client/messaging/threads`;
  },
  editChatThread(thread_uuid) {
    return `${this.base}/api/message/thread/${thread_uuid}/update`;
  },
  deleteChatThread(thread_uuid) {
    return `${this.base}/v4/client/messaging/threads/${thread_uuid}`;
  },
  getMembersChatThread(thread_uuid) {
    return `${
      this.base
    }/v4/client/messaging/threads/${thread_uuid}/users`;
  },
  getChatThreadRequest(thread_uuid) {
    return `${this.base}/v4/client/messaging/threads/${thread_uuid}`;
  },
  addMembersChatThread(thread_uuid) {
    return `${
      this.base
    }/v4/client/messaging/threads/${thread_uuid}/users`;
  },
  removeMemberFromChat(thread_uuid, user_uuid) {
    return `${
      this.base
    }/v4/client/messaging/threads/${thread_uuid}/users/${user_uuid}`;
  },
  searchChatThreads(keyword) {
    return `${
      this.base
    }/v4/client/messaging/threads?search=${keyword}`;
  },
  markAsReadThread(thread_uuid) {
    return `${
      this.base
    }/client/messaging/threads/${thread_uuid}/read`;
  },
  get1v1ChatRequest(recipient_uuid) {
    return `${
      this.base
    }/client/messaging/threads/private/${recipient_uuid}`;
  },
  uploadUserProfileImage(imageIdentifier) {
    return `${this.mediaBase}/images/${imageIdentifier}?apikey=${
      this.mediaKey
    }`;
  },
  uploadDocument() {
    console.log('uploadDocument apiroutes');
    return `${this.mediaBase}/files/documents`;
  },
  hideUserRequest() {
    return `${this.base}/client/account/hide`;
  },
  unhideUserRequest() {
    return `${this.base}/client/account/unhide`;
  },
  getDocs(id, type, lang) {
    return `${this.base}/content/app/${id}/docs/${type}/${lang}`;
  },
  getCallToken() {
    return `${this.base}/api/call/token`;
  },
  getAllThreads() {
    return `${this.base}/api/message/threads/all`;
  },
  getThread(id) {
    return `${this.base}/api/message/thread/${id}/messages/all`;
  },
  postThread(id) {
    return `${this.base}/api/message/thread/${id}/message/new`;
  },
  searchSuggestion(searchInput) {
    return `${this.base}/v4/client/search/all?search=${searchInput}`;
  },
  ImageToDownload(link) {
    return `${this.mediaBase}/public/images/${link}`;
  },
  DocumentToDownload(identifier) {
    return `${this.mediaBase}/files/documents/${identifier}`;
  },
  getPost(postId) {
    return `${this.base}/v4/client/messaging/posts/${postId}`;
  },

  getPageData(page_uuid) {
    return `${this.base}/v4/client/app/pages/${page_uuid}`;
  },
  deleteUser() {
    return `${this.base}/v4/client/account/profile`;
  },
  setUserOnline() {
    return `${this.base}/v4/client/account/online`;
  },
  setUserOffline() {
    return `${this.base}/v4/client/account/offline`;
  },
};
