let env = window.MESENSEI_ENV || "development";
let apiHost = "";
let mediaHost = "";
let mediaApikey = "";
let appHost = "";

export default {
  init(window) {
    env = window.MESENSEI_ENV || "development";
    apiHost = window.MESENSEI_API_HOST || "https://devapi.mesensei.com";
    mediaHost = window.MESENSEI_MEDIA_HOST || "https://media.mesensei.com";
    mediaApikey = window.MESENSEI_MEDIA_APIKEY || "hiekkalaatikko123";
  },

  get apiHost() {
    return apiHost;
  },

  get appHost() {
    return appHost;
  },

  get mediaHost() {
    return mediaHost;
  },

  get mediaApikey() {
    return mediaApikey;
  },

  get isProduction() {
    return env === "production";
  },

  get isDevelopment() {
    return env === "development";
  },

  get isMobile() {
    return env === "mobile";
  },
};
