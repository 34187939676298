import UserConstants from "../constants/UserConstants";

const {
  GET_USER,
  FILTER_SEARCH,
  GET_USER_DETAIL,
  GET_MY_PROFILE_DETAIL,
  ENDORSE_USER,
  REMOVE_ENDORSE_USER,
  FAVOURITE_USER,
  REMOVE_FAVOURITE_USER,
  GET_FAVOURITE_USERS,
  GET_MATCH_RECOMMENDATION,
  GET_SAME_TOPIC_USERS,
  SORT_RESULT,
  SEARCH_TOPIC,
  SELECTED_SORT_OPTION,
  UPDATE_EDITED_USER_PROFILE,
  UPLOAD_PROFILE_IMAGE,
  UPLOAD_DOCUMENT,
  GET_TOPIC,
  EMPTY_USER,
  HIDE_USER,
  UNHIDE_USER,
  SEND_FEEDBACK,
  TOGGLE_ONLINE,
  GET_TOPIC_DISCUSSION_THREAD,
} = UserConstants;

export const getUser = () => ({
  type: `${GET_USER}_REQUEST`,
});

export const filterSearch = (searchInput) => ({
  type: `${FILTER_SEARCH}_REQUEST`,
  payload: { searchInput },
});

export const sortResult = (params) => {
  return {
    type: `${SORT_RESULT}_REQUEST`,
    payload: params,
  };
};

export const selectedSortOption = (selectedOption) => ({
  type: `${SELECTED_SORT_OPTION}_REQUEST`,
  payload: { selectedOption },
});

export const getUserDetail = (userId) => ({
  type: `${GET_USER_DETAIL}_REQUEST`,
  payload: { userId },
});

export const getUserXTotalCount = (count) => ({
  type: "GET_COUNT_REQUEST",
  payload: { count },
});

export const getMyProfileDetail = () => ({
  type: `${GET_MY_PROFILE_DETAIL}_REQUEST`,
});

export const endorseUser = (topicId, userId) => ({
  type: `${ENDORSE_USER}_REQUEST`,
  payload: { topicId, userId },
});

export const removeEndorseUser = (topicId, userId) => ({
  type: `${REMOVE_ENDORSE_USER}_REQUEST`,
  payload: { topicId, userId },
});

export const favouriteUser = (userId) => ({
  type: `${FAVOURITE_USER}_REQUEST`,
  payload: { userId },
});

export const removeFavouriteUser = (userId) => ({
  type: `${REMOVE_FAVOURITE_USER}_REQUEST`,
  payload: { userId },
});

export const getFavouriteUsers = () => ({
  type: `${GET_FAVOURITE_USERS}_REQUEST`,
});

export const getMatchRecommendations = () => ({
  type: `${GET_MATCH_RECOMMENDATION}_REQUEST`,
});

export const getSameTopicUsers = (topicId) => ({
  type: `${GET_SAME_TOPIC_USERS}_REQUEST`,
  payload: { topicId },
});

//Get a topic discussion_thread action
export const getTopicDiscussionThread = (topicId) => ({
  type: `${GET_TOPIC_DISCUSSION_THREAD}_REQUEST`,
  payload: { topicId },
});

export const getSearchTopic = (topicSearchInput, page, limit) => ({
  type: `${SEARCH_TOPIC}_REQUEST`,
  payload: { topicSearchInput, page, limit },
});

export const resetTopic = () => ({
  type: `${SEARCH_TOPIC}_RESET`,
});

export const getTopic = (id) => ({
  type: `${GET_TOPIC}_REQUEST`,
  id,
});

export const emptyUser = () => ({
  type: `${EMPTY_USER}_REQUEST`,
});

export const updateEditedUserProfile = (editFields) => ({
  type: `${UPDATE_EDITED_USER_PROFILE}_REQUEST`,
  payload: { editFields },
});

export const uploadUserProfileImage = (imageIdentifier, imageData) => ({
  type: `${UPLOAD_PROFILE_IMAGE}_REQUEST`,
  payload: { imageIdentifier, imageData },
});

export const uploadDocumentCancel = () => ({
  type: `${UPLOAD_DOCUMENT}_CANCEL`,
});

export const uploadDocument = (documentData) => ({
  type: `${UPLOAD_DOCUMENT}_REQUEST`,
  payload: { documentData },
});

export const hideUserRequest = () => ({
  type: `${HIDE_USER}_REQUEST`,
});

export const unhideUserRequest = () => ({
  type: `${UNHIDE_USER}_REQUEST`,
});

export const sendFeedbackRequest = (feedbackForm) => ({
  type: `${SEND_FEEDBACK}_REQUEST`,
  payload: feedbackForm,
});

export const toggleOnline = (online, userId) => ({
  type: `${TOGGLE_ONLINE}_REQUEST`,
  payload: { online, userId },
});

export default {
  getUser,
  filterSearch,
  sortResult,
  getUserDetail,
  getMyProfileDetail,
  endorseUser,
  removeEndorseUser,
  favouriteUser,
  removeFavouriteUser,
  getFavouriteUsers,
  getMatchRecommendations,
  getSameTopicUsers,
  getSearchTopic,
  resetTopic,
  selectedSortOption,
  updateEditedUserProfile,
  uploadUserProfileImage,
  uploadDocument,
  uploadDocumentCancel,
  getUserXTotalCount,
  getTopic,
  emptyUser,
  hideUserRequest,
  unhideUserRequest,
  sendFeedbackRequest,
  toggleOnline,
};
