import firebase from "firebase/app";
import "firebase/messaging";

if (!firebase.apps.length)
  firebase.initializeApp({
    apiKey: "AIzaSyBCtP86dTWCAj5cUUD90I0BlNVyUbEApFk",
    authDomain: "mesensei-48fa4.firebaseapp.com",
    databaseURL: "https://mesensei-48fa4.firebaseio.com",
    projectId: "mesensei-48fa4",
    storageBucket: "mesensei-48fa4.appspot.com",
    messagingSenderId: "393492577987",
    appId: "1:393492577987:web:adf33ea926ea4cb2c32c76",
  });

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const getToken = (setTokenFound) => {
  return firebase.messaging.isSupported()
    ? messaging
        .getToken({
          vapidKey:
            "BAHvCk23HEx1nG42KJJLsN0LG9UiOkWdUOejWaG24uHolnPjDOIrMoWmCSyPe9iFGZxShtYO888n3_3ykFAqOtY",
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log("current token for client: ", currentToken);
            setTokenFound(currentToken, true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
            setTokenFound(null, false);
            // shows on the UI that permission is required
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // catch error while creating client token
        })
    : null;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });

export const onTokenRefreshChange = () =>
  new Promise((resolve) => {
    messaging.onTokenRefresh((token) => {
      resolve(token);
    });
  });
